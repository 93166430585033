﻿import { throttle } from "@shared/Utils";

interface MarginObserverOptions {
  marginThreshold: number;
}

export class MarginObserverEffects {
  public constructor() {
    this.init();
  }

  options: MarginObserverOptions = {
    marginThreshold: 10,
  };

  init(): void {
    const effectContainers = document.querySelectorAll(".js-margin-observe");

    effectContainers.forEach((item) => {
      this.ValidateDistance(item, this.options);
      window.addEventListener(
        "resize",
        throttle(() => this.ValidateDistance(item, this.options), 100),
      );
    });
  }
  ValidateDistance(item: Element, options: MarginObserverOptions): void {
    const rect = item.getBoundingClientRect();
    if (rect.left > options.marginThreshold) {
      item.classList.add("show-effect");
    } else {
      item.classList.remove("show-effect");
    }
  }
}

document.addEventListener("DOMContentLoaded", (): void => {
  new MarginObserverEffects();
});
